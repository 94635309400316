import cx from 'classnames'
import styles from './Button.module.css'

export const Button = ({ children, className, onClick, href, icon, as, filled, gray, ...props }) => {
  const Element = as || 'button'
  const classes = cx(styles.button, { [styles.filled]: filled, [styles.gray]: gray }, className)

  const inner = (
    <span className={styles.inner}>
      {icon && <span className={styles.icon}>{icon}</span>}
      {children}
    </span>
  )

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className={classes} {...props}>
        {inner}
      </a>
    )
  }
  return (
    <Element className={classes} onClick={onClick} {...props}>
      {inner}
    </Element>
  )
}
