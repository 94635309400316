import { AUTO, S, M, L, FULL } from 'shared/sizes'
import cx from 'classnames'
import styles from './MaxWidth.module.css'

export const MaxWidth = ({ size, children }) => {
  const sizes = {
    [AUTO]: styles.s,
    [S]: styles.s,
    [M]: styles.m,
    [L]: styles.l,
    [FULL]: styles.full,
  }

  const sizeClass = size ? sizes[size] : sizes[M]

  return <div className={cx(styles.maxWidth, sizeClass)}>{children}</div>
}
