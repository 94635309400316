export function getSubDomain(host) {
  const domainMap = {
    'hogeschoolutrecht.design': 'hu',
  }

  const isDev = host.includes('localhost')
  const splitHost = host.split('.')

  if (domainMap[host]) {
    return domainMap[host]
  }

  if ((!isDev && splitHost.length === 3) || (isDev && splitHost.length === 2)) {
    const sub = splitHost[0]
    return sub
  }

  return 'root'
}

export function withSubdomain(fn) {
  fn.getInitialProps = ({ req }) => {
    let subdomain = null

    if (req) {
      subdomain = getSubDomain(req.headers.host)
    }

    if (typeof window !== 'undefined') {
      subdomain = getSubDomain(window.location.host)
    }

    return { subdomain }
  }

  return fn
}
