import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Overlay } from 'components/Overlay'
import { RichText } from 'components/RichText'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import styles from './FeedbackOverlay.module.css'

export const FeedbackOverlay = ({ onClose }) => {
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [email, setEmail] = useState()
  const [feedback, setFeedback] = useState()

  async function onSubmit(e) {
    e.preventDefault()
    setError(null)

    const { success } = await fetch('/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, feedback }),
    }).then((r) => r.json())

    if (success) {
      return setSuccess(true)
    }

    setError('Er ging iets mis, probeer het nog eens...')
  }

  return (
    <Overlay onClose={onClose}>
      {success ? (
        <RichText>
          <h2>{staticText.thanksFeedbackTitle}</h2>
          <p>{staticText.thanksFeedbackText}</p>
        </RichText>
      ) : (
        <>
          <div className={styles.group}>
            <RichText>
              <h2>{staticText.feedbackTitle}</h2>
              <p>{staticText.feedbackText}</p>
            </RichText>
          </div>

          <form onSubmit={onSubmit}>
            <div className={styles.group}>
              <Input
                label={staticText.yourEmail}
                value={email || ''}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.group}>
              <Input
                label={staticText.yourFeedback}
                value={feedback || ''}
                type="text"
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
            <Button>{staticText.submit}</Button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </form>
        </>
      )}
    </Overlay>
  )
}
