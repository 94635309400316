import { InputContainer } from 'components/InputContainer'
import cx from 'classnames'
import React from 'react'
import styles from './Input.module.css'

export const Input = ({ className, label, value, onChange, type = 'text', isInline, ...props }) => {
  const inputEl = (
    <input
      className={cx({ [styles.inlineInput]: isInline, [styles.input]: !isInline }, className)}
      onChange={onChange}
      size={isInline ? (value ? value.toString().length : 1) : null}
      type={type}
      value={value}
      {...props}
    />
  )

  if (label) {
    return <InputContainer label={label}>{inputEl}</InputContainer>
  }

  return inputEl
}
