import styles from './Loading.module.css'

export const Loading = () => (
  <div className={styles.container}>
    <div className={styles.loader}>
      <div className={styles.one} />
      <div className={styles.two} />
    </div>
  </div>
)
