import { MaxWidth } from 'components/MaxWidth'
import { S } from 'shared/sizes'
import styles from './PageTitle.module.css'

export const PageTitle = ({ isEditing, setTitle, title }) => {
  const onTitleChange = (value) => {
    const fixedValue = value.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ') // Remove line breaks and double spaces
    setTitle(fixedValue)
  }

  return (
    <MaxWidth size={S}>
      <Heading1 isEditing={isEditing} onChange={onTitleChange}>
        {title}
      </Heading1>
    </MaxWidth>
  )
}

const Heading1 = ({ children, onChange, isEditing }) => {
  if (isEditing) {
    return <input className={styles.heading1} type="text" value={children} onChange={(e) => onChange(e.target.value)} />
  }

  return <h1 className={styles.heading1}>{children}</h1>
}
