export const AddIcon = () => (
  <SVG>
    <path d="M14 2a2 2 0 00-4 0v8H2a2 2 0 000 4h8v8a2 2 0 004 0v-8h8a2 2 0 100-4h-8V2z" />
  </SVG>
)

export const AddGroupIcon = () => (
  <SVG>
    <path d="M14.4 5.9H0V3.5h14.4v2.4zm0 4.8H0V8.3h14.4v2.4zM0 15.5h9.6v-2.4H0v2.4zm16.801-2.4V8.3h2.4v4.8H24v2.4h-4.799v4.8h-2.4v-4.8H12v-2.4H16.8z" />
  </SVG>
)

export const BoldIcon = () => (
  <SVG>
    <path d="M14.348 20.932H6.08v-7.488h5.346c4.572 0 5.343 1.224 5.343 3.97 0 2.725-1.854 3.382-2.422 3.518zM6.08 3.068l4.325-.003c.49.04 2.922.41 2.922 3.656 0 3.252-3.095 3.617-3.625 3.655H6.081V3.068zm9.049 7.687c.762-.992 1.278-2.304 1.278-4.034C16.409 1.693 12.62.13 10.52 0H3v24h11.634l.125-.021c1.76-.292 5.092-2.042 5.092-6.565 0-4.283-2.122-6.002-4.721-6.659z" />
  </SVG>
)

export const ButtonsIcon = () => (
  <SVG>
    <path d="M12.0519 14.8285L13.4661 16.2427L17.7087 12L13.4661 7.7574L12.0519 9.17161L13.8803 11H6.34318V13H13.8803L12.0519 14.8285Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5C2.79086 1 1 2.79086 1 5V19ZM5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z"
    />
  </SVG>
)

export const CheckIcon = () => (
  <SVG>
    <path d="M20.998 3L9.001 14.998l-6.002-6L0 12.002l9 8.998 15-15L20.998 3z" />
  </SVG>
)

export const ChevronIcon = () => (
  <SVG>
    <path d="M7.398 0L5 2.4l9.6 9.598L5 21.6 7.4 24l12-12.002L7.4 0h-.003z" />
  </SVG>
)

export const CloseIcon = () => (
  <SVG>
    <path d="M7.011 4.517a1.762 1.762 0 00-2.494 0c-.69.69-.69 1.805 0 2.494l4.99 4.99-4.99 4.989a1.762 1.762 0 000 2.494c.69.688 1.807.688 2.494 0l4.99-4.988 4.989 4.988a1.765 1.765 0 002.494 0 1.765 1.765 0 000-2.494l-4.988-4.988 4.988-4.99a1.765 1.765 0 000-2.495 1.762 1.762 0 00-2.494 0l-4.988 4.989-4.99-4.989z" />
  </SVG>
)

export const ColoursIcon = () => (
  <SVG>
    <path d="M20.435 15.31l2.529 2.543a3.612 3.612 0 010 5.084 3.559 3.559 0 01-5.056 0 3.61 3.61 0 010-5.084l2.527-2.543zM7.888 0c1.501 0 2.744 1.325 2.95 3.047l10.107 10.18-9.268 9.333a3.555 3.555 0 01-5.054 0l-5.056-5.09a3.618 3.618 0 010-5.092L4.91 9.013V3.548C4.91 1.588 6.243 0 7.89 0zm2.979 6.47v4.176c0 .052-.001.102-.003.154H8.47a.81.81 0 00.014-.154v-1.84l-5.232 5.269a1.208 1.208 0 000 1.698l5.056 5.09c.465.47 1.218.47 1.684 0l7.583-7.637-6.708-6.756zM7.888 2.84c-.329 0-.596.317-.596.708v3.065l1.192-1.2V3.548c0-.39-.267-.709-.596-.709z" />
  </SVG>
)

export const DeleteIcon = () => (
  <SVG>
    <path d="M16.606 0a2.521 2.521 0 012.514 2.526V3.79h3.771a1.26 1.26 0 011.258 1.264 1.26 1.26 0 01-1.258 1.263h-1.257V20.21A3.78 3.78 0 0117.863 24H7.806a3.78 3.78 0 01-3.772-3.79V6.316H2.777A1.26 1.26 0 011.52 5.053a1.26 1.26 0 011.257-1.264H6.55V2.526A2.521 2.521 0 019.063 0h7.543zm2.514 6.316H6.549V20.21a1.26 1.26 0 001.257 1.263h10.057a1.26 1.26 0 001.257-1.264V6.316zM11.263 9v10.286H8.52V9h2.743zm5.486 0v10.286h-2.743V9h2.743zm-.143-6.474H9.063V3.79h7.543V2.526z" />
  </SVG>
)

export const DividerIcon = () => (
  <SVG>
    <path d="M12.172 17.032l5.85 5.975L16.071 25l-3.9-3.983-3.9 3.983-1.95-1.992 5.85-5.975zm8.273-5.67c.763 0 1.38.63 1.38 1.409 0 .777-.617 1.408-1.38 1.408H3.9c-.761 0-1.379-.63-1.379-1.409 0-.777.618-1.408 1.379-1.408h16.546zM16.172 1l2 1.956-6 5.87-6-5.87L8.171 1l4.001 3.912 4-3.912z" />
  </SVG>
)

export const DoDontIcon = () => (
  <SVG>
    <path d="M20.156 0a4.364 4.364 0 014.364 4.364v15.272A4.364 4.364 0 0120.156 24H4.884A4.364 4.364 0 01.52 19.636V4.364A4.364 4.364 0 014.884 0h15.272zm0 2.182H4.884a2.183 2.183 0 00-2.182 2.182v15.272c0 1.205.977 2.182 2.182 2.182h15.272a2.183 2.183 0 002.182-2.182V4.364a2.183 2.183 0 00-2.182-2.182zM16.958 7.5l1.562 1.583L10.708 17 6.02 12.25l1.562-1.584 3.126 3.168 6.25-6.334z" />
  </SVG>
)

export const DragIcon = () => (
  <SVG>
    <path d="M16.52 18a3.001 3.001 0 010 6 3.001 3.001 0 010-6zm-9-4.5a3.001 3.001 0 010 6 3.001 3.001 0 010-6zm9-4.5a3.001 3.001 0 010 6 3.001 3.001 0 010-6zm-9-4.5a3.001 3.001 0 010 6 3.001 3.001 0 010-6zm9-4.5a3.001 3.001 0 010 6 3.001 3.001 0 010-6z" />
  </SVG>
)

export const EditIcon = () => (
  <SVG>
    <path d="M20.93 6.797L19.807 7.92 16.6 4.713l1.124-1.124a1.135 1.135 0 011.603 0l1.603 1.604c.444.443.444 1.16 0 1.604zM10.508 17.219l7.237-7.237-3.206-3.207-7.238 7.237 3.207 3.207zM20.981.333a1.132 1.132 0 011.603 0l1.604 1.603c.443.442.443 1.16 0 1.603l-.99.989a3.404 3.404 0 01-.665 3.872L10.508 20.425l-6.414-6.413L16.121 1.986a3.402 3.402 0 013.87-.665l.99-.988zM2.958 15.063L.52 24l8.936-2.437-6.498-6.5z" />
  </SVG>
)

export const EmbedIcon = () => (
  <SVG>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.12 8.758l4.8 3.569-4.8 3.568V8.758zm-9.6-1.19C.52 5.599 2.132 4 4.12 4h16.8c1.988 0 3.6 1.598 3.6 3.569v9.515c0 1.972-1.612 3.57-3.6 3.57H4.12c-1.988 0-3.6-1.598-3.6-3.57V7.57zm3.6-1.189h16.8c.664 0 1.2.533 1.2 1.19v9.515c0 .657-.536 1.19-1.2 1.19H4.12c-.662 0-1.2-.533-1.2-1.19V7.57c0-.657.538-1.19 1.2-1.19z"
    />
  </SVG>
)

export const IframeIcon = () => (
  <SVG>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7a1 1 0 00-1 1v8a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1h-4zm3 2h-2v6h2V9z"
    />
    <path d="M6 7a1 1 0 000 2h4a1 1 0 100-2H6zM6 11a1 1 0 100 2h4a1 1 0 100-2H6zM5 16a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3a3 3 0 00-3 3v12a3 3 0 003 3h16a3 3 0 003-3V6a3 3 0 00-3-3H4zm16 2H4a1 1 0 00-1 1v12a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1z"
    />
  </SVG>
)

export const FileIcon = () => (
  <SVG>
    <path d="M20.134 6a5.99 5.99 0 00-4.28-3.48V6h4.28zm-6.67-3.6h-8.36c-.66 0-1.195.538-1.195 1.2v16.8c0 .662.535 1.2 1.194 1.2h14.334c.66 0 1.194-.538 1.194-1.2v-12h-7.167v-6zM1.52 3.6C1.52 1.612 3.124 0 5.103 0h9.556c4.618 0 8.361 3.76 8.361 8.4v12c0 1.988-1.604 3.6-3.583 3.6H5.103a3.591 3.591 0 01-3.583-3.6V3.6z" />
  </SVG>
)

export const GridIcon = () => (
  <SVG>
    <path d="M24 0v24H0V0h24zm-2.667 2.667H2.667v18.666h18.666V2.667zM18.5 13.5v5h-5v-5h5zm-8 0v5h-5v-5h5zm0-8v5h-5v-5h5zm8 0v5h-5v-5h5z" />
  </SVG>
)

export const H2Icon = () => (
  <SVG>
    <path d="M2.522 19v-5.948h6.45v5.947H11.5V5H8.972v5.927h-6.45V5H0v14h2.522zM24 19v-2.091h-5.985v-.095l2.369-2.42c.89-.86 1.582-1.593 2.076-2.203.494-.609.84-1.163 1.037-1.662.197-.499.295-1.007.295-1.524 0-.773-.195-1.461-.584-2.064-.389-.602-.931-1.076-1.627-1.422-.695-.347-1.504-.52-2.425-.52-.908 0-1.712.178-2.412.533-.7.355-1.249.857-1.647 1.504-.398.647-.597 1.41-.597 2.286h2.368c0-.706.205-1.264.614-1.676.41-.411.956-.617 1.64-.617.654 0 1.197.188 1.63.563.435.376.652.89.652 1.541 0 .576-.173 1.106-.517 1.592-.344.485-.857 1.074-1.536 1.767l-4.77 4.7v1.807H24z" />
  </SVG>
)

export const H3Icon = () => (
  <SVG>
    <path d="M2.412 18.5v-5.735h6.17V18.5H11V5H8.582v5.715h-6.17V5H0v13.5h2.412zm16.32.5c1.018 0 1.924-.17 2.718-.512.794-.342 1.419-.812 1.874-1.411.455-.599.68-1.282.676-2.05.005-.851-.262-1.563-.801-2.135-.54-.573-1.308-.928-2.307-1.065v-.107c.776-.16 1.4-.5 1.875-1.02.474-.522.708-1.164.703-1.927a3.267 3.267 0 00-.574-1.897c-.388-.572-.934-1.028-1.638-1.367-.703-.34-1.531-.509-2.484-.509-.924 0-1.759.163-2.504.49-.746.325-1.34.778-1.78 1.356a3.37 3.37 0 00-.69 2.013h2.487c.023-.572.276-1.024.76-1.357.482-.333 1.054-.5 1.713-.5.665 0 1.2.177 1.606.53.407.353.608.815.603 1.387a1.716 1.716 0 01-.693 1.438c-.467.363-1.07.545-1.808.545h-1.261v1.903h1.26c.902 0 1.607.195 2.115.586.509.39.761.893.756 1.51.005.603-.239 1.092-.731 1.467-.493.375-1.12.563-1.881.563-.716 0-1.32-.166-1.815-.496-.495-.33-.759-.773-.791-1.328H13.5a3.4 3.4 0 00.714 2.03c.453.585 1.069 1.042 1.847 1.37.778.329 1.668.493 2.672.493z" />
  </SVG>
)

export const H4Icon = () => (
  <SVG>
    <path d="M2.463 19.276v-5.64h6.301v5.64h2.47V5.999h-2.47v5.62h-6.3V6H0v13.277h2.463zm19.773 0v-2.463H24v-1.978h-1.764V6h-3.11l-5.85 8.901v1.912h6.577v2.464h2.383zm-2.321-4.596H15.83v-.104l3.976-6.024h.109v6.128z" />
  </SVG>
)

export const ImageIcon = () => (
  <SVG>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.142 19H21c.401 0 .748-.238.907-.579l-4.886-4.887a1.002 1.002 0 00-1.415 0L10.142 19zM3 5h18a1 1 0 011 1v9.686l-3.565-3.566a3.001 3.001 0 00-4.243 0L7.314 19H3a1 1 0 01-1-1V6a1 1 0 011-1zM0 6a3 3 0 013-3h18a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V6zm7 3a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116-.001 3 3 0 01-6 0z"
    />
  </SVG>
)

export const InfoIcon = () => (
  <SVG>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 12C0 5.376 5.376 0 12 0C18.624 0 24 5.376 24 12C24 18.624 18.624 24 12 24C5.376 24 0 18.624 0 12ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6ZM12 4.85999C11.34 4.85999 10.8 5.39999 10.8 6.05999C10.8 6.71999 11.34 7.25999 12 7.25999C12.66 7.25999 13.2 6.71999 13.2 6.05999C13.2 5.39999 12.66 4.85999 12 4.85999ZM10.8 10.776C10.8 10.116 11.34 9.57601 12 9.57601C12.66 9.57601 13.2 10.116 13.2 10.776V17.976C13.2 18.636 12.66 19.176 12 19.176C11.34 19.176 10.8 18.636 10.8 17.976V10.776Z"
    />
  </SVG>
)

export const ItalicIcon = () => (
  <SVG>
    <path d="M11.137 0L9.83 3.427h3.67L6.975 20.57H3.306L2 23.999h11.008l1.306-3.428h-3.67l6.524-17.143h3.668l1.306-3.429H11.137z" />
  </SVG>
)

export const LinkIcon = () => (
  <SVG>
    <path d="M5.76 9.902l2.035 2.038-4.072 4.072a2.881 2.881 0 004.072 4.074l4.074-4.074 2.036 2.036-4.072 4.074a5.76 5.76 0 01-8.146-8.146l4.072-4.074zm7.76-2.2a1.45 1.45 0 012.054 0 1.45 1.45 0 010 2.053l-6.16 6.162a1.453 1.453 0 01-2.055-2.054l6.161-6.162zm.155-6.055a5.625 5.625 0 017.954 7.952l-3.978 3.978-1.988-1.988L19.64 7.61a2.813 2.813 0 00-3.978-3.976L11.687 7.61 9.698 5.623l3.977-3.977z" />
  </SVG>
)

export const LockIcon = () => (
  <SVG>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.143 12.57H4.429c-.631 0-1.143.512-1.143 1.144v6.857c0 .63.512 1.143 1.143 1.143h13.714c.632 0 1.143-.512 1.143-1.143v-6.857c0-.632-.511-1.143-1.143-1.143zM11.286 2.286a4.572 4.572 0 014.571 4.571v3.429H6.714V6.856a4.572 4.572 0 014.572-4.571zm6.857 8a3.428 3.428 0 013.428 3.429v6.857a3.428 3.428 0 01-3.428 3.428H4.429A3.428 3.428 0 011 20.571v-6.857a3.428 3.428 0 013.429-3.429V6.856a6.857 6.857 0 0113.714 0v3.429z"
    />
  </SVG>
)

export const LoginIcon = () => (
  <SVG>
    <path d="M21.437 22.21h-5.052v-2.527h5.052V4.526h-5.052V1.999h5.052a2.527 2.527 0 012.527 2.527v15.157a2.527 2.527 0 01-2.527 2.527zM7.862 18.298l1.794 1.779 8.004-8.073-8.073-8.005L7.81 5.793l5.027 4.985H1.263a1.264 1.264 0 000 2.527h11.55l-4.951 4.993z" />
  </SVG>
)

export const LogoutIcon = () => (
  <SVG>
    <path d="M2.526 22.21H7.58v-2.527H2.526V4.526H7.58V1.999H2.526A2.527 2.527 0 000 4.526v15.157a2.527 2.527 0 002.526 2.527zm13.576-3.912l-1.793 1.779-8.005-8.073 8.073-8.005 1.778 1.794-5.027 4.985H22.7a1.264 1.264 0 010 2.527h-11.55l4.951 4.993z" />
  </SVG>
)

export const MenuIcon = () => (
  <SVG>
    <path d="M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 12.032a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM3 17.064a1 1 0 100 2h18a1 1 0 000-2H3z" />
  </SVG>
)

export const MoreIcon = () => (
  <SVG>
    <path d="M3 9a3 3 0 110 6 3 3 0 010-6zm9 0a3 3 0 110 6 3 3 0 010-6zm9 0a3 3 0 110 6 3 3 0 010-6z" />
  </SVG>
)

export const OlIcon = () => (
  <SVG>
    <path d="M7.882 18.878c0-.944.738-1.707 1.647-1.707h9.878c.91 0 1.646.763 1.646 1.707 0 .942-.736 1.707-1.646 1.707H9.529c-.91 0-1.647-.765-1.647-1.707zm0-6.83c0-.942.738-1.707 1.647-1.707h9.878c.91 0 1.646.765 1.646 1.708 0 .942-.736 1.707-1.646 1.707H9.529c-.91 0-1.647-.765-1.647-1.707zM9.53 4c-.91 0-1.647.765-1.647 1.707 0 .943.738 1.708 1.647 1.708h9.878c.91 0 1.646-.765 1.646-1.708 0-.942-.736-1.707-1.646-1.707H9.529zM4.48 8V3H3.23L2 3.752v1.152l1.094-.654h.03V8h1.356zm.402 6.5v-1.07H2.956v-.028l.462-.386c.376-.313.666-.582.87-.809.203-.226.345-.433.423-.62.08-.187.118-.375.118-.565 0-.306-.079-.574-.237-.802a1.541 1.541 0 00-.672-.53 2.561 2.561 0 00-1.027-.19c-.382 0-.715.067-1 .202a1.527 1.527 0 00-.66.578c-.155.25-.233.55-.233.896h1.272c-.002-.207.055-.366.17-.478a.621.621 0 01.451-.167c.185 0 .336.054.454.161a.573.573 0 01.177.446.701.701 0 01-.158.456c-.105.13-.248.27-.43.42l-1.84 1.523v.963h3.786zm-1.905 7a2.76 2.76 0 001.054-.188c.305-.125.544-.298.715-.518.172-.22.257-.473.256-.758a.99.99 0 00-.292-.742c-.198-.198-.494-.318-.887-.36v-.039c.29-.031.53-.134.72-.308a.856.856 0 00.278-.67 1.193 1.193 0 00-.231-.731 1.566 1.566 0 00-.65-.503 2.343 2.343 0 00-.953-.183c-.368 0-.693.065-.977.195-.284.13-.507.309-.669.537a1.339 1.339 0 00-.246.789H2.36a.46.46 0 01.18-.363.689.689 0 01.448-.14.6.6 0 01.407.136.426.426 0 01.154.348.423.423 0 01-.182.362.779.779 0 01-.484.142h-.494v.912h.494c.214 0 .389.047.524.142.136.094.202.215.199.362a.445.445 0 01-.17.37.68.68 0 01-.448.143.761.761 0 01-.468-.136.43.43 0 01-.188-.348H1c.002.302.087.57.257.803.17.233.402.415.7.548.297.132.637.198 1.02.198z" />
  </SVG>
)

export const SaveIcon = () => (
  <SVG>
    <path d="M16.271 0c.54 0 1.063.176 1.49.498l5.285 3.998c.6.454.954 1.158.954 1.904v13.998C24 22.386 22.356 24 20.338 24H3.66C1.641 24 0 22.385 0 20.398V3.601C0 1.615 1.641 0 3.661 0h12.61zM4.26 2.448h-.599a1.21 1.21 0 00-1.22 1.2v16.798c0 .662.546 1.2 1.22 1.2h16.677a1.21 1.21 0 001.22-1.2v-14l-5.083-3.843v4.952c0 .663-.546 1.2-1.22 1.2H5.48a1.21 1.21 0 01-1.221-1.2V2.448zm7.183 7.65c2.427 0 4.401 1.948 4.401 4.344 0 2.396-1.974 4.345-4.401 4.345-2.428 0-4.401-1.949-4.401-4.345s1.973-4.344 4.4-4.344zm0 2.386c-1.095 0-1.984.877-1.984 1.958 0 1.083.889 1.96 1.984 1.96 1.097 0 1.985-.877 1.985-1.96 0-1.081-.889-1.958-1.985-1.958zm2.59-10.036h-7.33v3.907h7.33V2.448z" />
  </SVG>
)

export const SearchIcon = () => (
  <SVG>
    <path d="M15.546 4.771a7.617 7.617 0 010 10.775 7.62 7.62 0 110-10.775zm2.638 11.62c3.103-3.985 2.822-9.752-.841-13.416-3.969-3.967-10.4-3.967-14.368 0-3.967 3.967-3.967 10.4 0 14.367 3.664 3.665 9.43 3.945 13.415.842l.054.055 5.388 5.39a1.27 1.27 0 001.796-1.796l-5.387-5.39a27.821 27.821 0 01-.057-.053z" />
  </SVG>
)

export const SettingsIcon = () => (
  <SVG>
    <path d="M13.68 0a.5.5 0 01.5.498v3.084c.75.194 1.45.484 2.106.86l1.907-1.907a.498.498 0 01.703-.003l2.572 2.572a.501.501 0 01-.003.703l-1.907 1.907c.374.658.666 1.356.862 2.104h3.082c.275 0 .498.222.498.5v3.363a.5.5 0 01-.498.501H20.42a8.686 8.686 0 01-.862 2.106l1.907 1.907c.195.195.195.51.003.703l-2.572 2.57a.502.502 0 01-.703-.003l-1.907-1.907a8.492 8.492 0 01-2.106.86v3.084a.498.498 0 01-.5.498h-3.362a.5.5 0 01-.5-.498v-3.084a8.51 8.51 0 01-2.104-.86l-1.907 1.907a.499.499 0 01-.703.003l-2.574-2.57a.5.5 0 01.003-.703l1.909-1.907a8.686 8.686 0 01-.862-2.106H.498a.498.498 0 01-.498-.5v-3.363a.5.5 0 01.498-.501H3.58a8.704 8.704 0 01.862-2.104L2.533 5.807a.498.498 0 01-.003-.703l2.574-2.572a.502.502 0 01.703.003l1.907 1.907a8.51 8.51 0 012.104-.86V.498c0-.275.222-.498.5-.498h3.362zM12 7.2A4.8 4.8 0 007.2 12 4.8 4.8 0 1012 7.2z" />
  </SVG>
)

export const TemplateIcon = () => (
  <SVG>
    <path d="M11 10.5V22H2V10.5h9zM22 20v2h-9v-2h9zM8.751 12.8H4.25v6.9h4.501v-6.9zM22 15.5v2h-9v-2h9zm0-4.5v2h-9v-2h9zm0-9v6.5H2V2h20zm-2.222 2.167H4.222v2.167h15.556V4.167z" />
  </SVG>
)

export const TextIcon = () => (
  <SVG>
    <path d="M13.848 22V3.728H20.5V1H4v2.728h6.642V22h3.206z" />
  </SVG>
)

export const ToggleIcon = () => (
  <SVG>
    <path d="M2.358 1.079C2.125 1.449 2 1.877 2 2.315v19.37a2.316 2.316 0 003.556 1.957l15.363-9.686a2.31 2.31 0 000-3.913L5.556.358a2.318 2.318 0 00-3.198.721z" />
  </SVG>
)

export const UlIcon = () => (
  <SVG>
    <path d="M8.83 18.878c0-.944.737-1.707 1.646-1.707h9.878c.91 0 1.646.763 1.646 1.707 0 .942-.736 1.707-1.646 1.707h-9.878c-.91 0-1.647-.765-1.647-1.707zM2 20.585h3.415v-3.414H2v3.414zm6.83-8.536c0-.943.737-1.707 1.646-1.707h9.878c.91 0 1.646.764 1.646 1.707 0 .942-.736 1.707-1.646 1.707h-9.878c-.91 0-1.647-.765-1.647-1.707zM2 13.756h3.415v-3.415H2v3.415zM10.476 4c-.91 0-1.647.765-1.647 1.707 0 .943.738 1.708 1.647 1.708h9.878c.91 0 1.646-.765 1.646-1.708C22 4.765 21.264 4 20.354 4h-9.878zM2 7.415h3.415V4H2v3.415z" />
  </SVG>
)

export const UnlockIcon = () => (
  <SVG>
    <path d="M19.143 12.571H5.429c-.631 0-1.143.512-1.143 1.143v6.857c0 .631.512 1.143 1.143 1.143h13.714c.632 0 1.143-.512 1.143-1.143v-6.857c0-.63-.511-1.143-1.143-1.143zm1.143-5.714H18a4.572 4.572 0 00-9.143 0v3.429h10.286a3.428 3.428 0 013.428 3.428v6.857A3.428 3.428 0 0119.143 24H5.429A3.428 3.428 0 012 20.571v-6.857a3.428 3.428 0 013.429-3.428H6.57V6.857a6.857 6.857 0 0113.715 0z" />
  </SVG>
)

export const ViewIcon = () => (
  <SVG>
    <path d="M12 5c6.93 0 12 7.5 12 7.5S18.93 20 12 20 0 12.5 0 12.5 5.07 5 12 5zm0 3.214c-2.41 0-4.364 1.917-4.364 4.285 0 2.368 1.954 4.286 4.364 4.286s4.364-1.918 4.364-4.286c0-2.368-1.954-4.285-4.364-4.285zm0 2.142c1.204 0 2.182.96 2.182 2.143 0 1.184-.978 2.143-2.182 2.143s-2.182-.959-2.182-2.143c0-1.184.978-2.143 2.182-2.143z" />
  </SVG>
)

const SVG = ({ children }) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    {children}
  </svg>
)
