export const staticText = {
  account: 'Account',
  addGroup: 'Groep toevoegen',
  addPage: 'Pagina toevoegen',
  appName: 'Lasagna.app',
  areYouSureClose: 'Weet je zeker dat je wilt weggaan? Wijzigingen worden niet opgeslagen.',
  at: 'om',
  beingEdited: 'Pagina wordt bewerkt',
  cancel: 'Annuleren',
  changeImage: 'Wijzig afbeelding',
  changePageNameText: 'Wijzig de paginanaam met het veld hieronder.',
  changePageNameTitle: 'Paginanaam bewerken',
  changeGroupNameText: 'Wijzig de groepnaam met het veld hieronder.',
  changeGroupNameTitle: 'Groepnaam bewerken',
  close: 'Sluiten',
  confirmDeleteText: 'Als het bestand nog binnen Lasagna gebruikt wordt zal deze daar een foutmelding geven.',
  confirmDeleteTitle: 'Weet je zeker dat je het bestand wilt verwijderen?',
  defaultPageDescription: 'Met Lasagna leg je gemakkelijk het design systeem van jouw organisatie vast',
  defaultPageTitle: 'Design Systeem',
  delete: 'Verwijderen',
  deletePageText:
    'Als je de pagina wilt verwijderen kan dat met de knop hieronder. Let op: dit is niet terug te draaien.',
  deletePageTitle: 'Pagina verwijderen',
  edit: 'Pagina bewerken',
  editingPage: 'Aan het bewerken:',
  email: 'Email',
  enterGroupName: 'Groeptitel',
  enterTitle: 'Paginatitel',
  feedbackButtonLabel: 'Feedback of lijkt iets niet te werken?',
  feedbackText: 'Graag horen we als je iets mist of iets hebt gevonden dat niet lijkt te werken.',
  feedbackTitle: 'Feedback over Lasagna.app',
  fromComponent: 'Begin met het toevoegen van een component',
  fromTemplate: 'Of begin vanuit een template',
  fullWidth: 'Volledige breedte',
  large: 'Groot',
  lastEditOn: 'Laatst bijgewerkt op',
  loadingTitle: 'Lasagna aan het bereiden',
  management: 'Beheer',
  media: 'Media',
  mediaLibrary: 'Media Bibliotheek',
  medium: 'Medium',
  newPage: 'Nieuwe pagina',
  newGroup: 'Nieuwe groep',
  notFound: 'Deze Lasagna kon niet worden gevonden',
  pageInEditMode: 'Deze pagina staat bij een andere gebruiker in bewerkmodus',
  pageLockedTitle: 'Deze pagina is beveiligd',
  pageLockedText: 'Voer het wachtwoord in om de pagina te bekijken.',
  pageMenu: "Pagina's",
  password: 'Wachtwoord',
  passwordProtect: 'Wachtwoord instellen',
  passwordProtectInputLabel: 'Wachtwoord',
  passwordProtectInputLabelRepeat: 'Wachtwoord (ter controle)',
  passwordProtectCharacters: 'Het wachtwoord moet minimaal 6 karakters lang zijn.',
  passwordProtectShouldMatch: 'De ingevoegde wachtwoorden zijn niet hetzelfde.',
  passwordProtectText:
    'Een wachtwoord beveiligde pagina kan alleen bekeken worden wanneer de bezoeker het wachtwoord weet en invoert. Zo kan de inhoud niet bekeken worden door ongewenste gebruikers.',
  passwordProtectTitle: 'Wil je de pagina met een wachtwoord beveiligen?',
  passwordRemoved: 'Wachtwoord verwijderd',
  passwordSaved: 'Wachtwoord bijgewerkt',
  removePassword: 'Wachtwoord verwijderen',
  removePasswordText:
    'Door het wachtwoord te verwijderen ontgrendel je de pagina zodat iedereen hem kan bekijken. Je kunt altijd op een later moment weer een wachtwoord op de pagina zetten.',
  removePasswordTitle: 'Pagina ontgrendelen?',
  save: 'Opslaan',

  saved: 'Wijzigingen opgeslagen',
  saveOrUndo: 'Wil je de wijzigingen die je hebt gemaakt opslaan, of ongedaan maken?',
  search: 'Zoeken...',
  settings: 'Instellingen',
  signIn: 'Inloggen',
  signInText: 'Log in om de inhoud van de Lasagna te beheren.',
  signInTitle: 'Inloggen',
  signOut: 'Uitloggen',
  size: 'Formaat',
  small: 'Klein',
  smallAuto: 'Klein (zonder schalen)',
  submit: 'Versturen',
  thanksFeedbackText: 'We zullen je feedback zo snel mogelijk bekijken en contact met je opnemen.',
  thanksFeedbackTitle: 'Bedankt voor je feedback',
  undoChanges: 'Wijzigingen ongedaan maken',
  undone: 'Wijzigingen ongedaan gemaakt',
  unlock: 'Ontgrendelen',
  uploadFiles: 'Bestand(en) uploaden',
  use: 'Gebruiken',
  wantToEditAnyway:
    'Wil je de pagina overnemen en toch bewerken? Wanneer jullie beide de pagina bewerken kan er data verloren gaan.',
  watch: 'Bekijken',
  youHaventSaved: 'Je hebt nog niet opgeslagen.',
  yourEmail: 'Jouw e-mailaddress',
  yourFeedback: 'Jouw Feedback',
}
