import { AppContext } from "shared/AppContext";
import { getImgixUrl } from "shared/imgix";
import { LoadingView } from "views/Loading";
import { NotFound } from "views/NotFound";
import { Notification } from "components/Notification";
import { useAuth, getFirebaseInstances, useFirestore } from "shared/firebase";
import { useState } from "react";

export const PageWrapper = ({ children, subdomain, needsUser }) => {
  const [notification, setNotification] = useState();
  const firebaseInstances = getFirebaseInstances(subdomain);
  const imgixUrl = getImgixUrl(subdomain);

  const { userLoading, user } = useAuth(firebaseInstances.auth);
  const settings = useFirestore({
    database: firebaseInstances.database,
    path: "/settings/settings",
  });

  const isLoading =
    userLoading || (firebaseInstances.database && settings.loading);

  return (
    <AppContext.Provider
      value={{
        firebaseInstances,
        imgixUrl,
        settings: settings.data,
        user,
        setNotification,
      }}
    >
      {renderView()}
      {notification && <Notification {...notification} />}
    </AppContext.Provider>
  );

  function renderView() {
    if (isLoading) {
      return <LoadingView />;
    }

    if (!subdomain || !firebaseInstances.database || (needsUser && !user)) {
      return <NotFound />;
    }

    return children;
  }
};
