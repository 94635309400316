import { staticText } from "content/staticText";
import { useAppContext } from "shared/AppContext";
import Head from "next/head";
import React from "react";

const GA_MEASUREMENT_ID = "UA-157896653-1";

export const PageHead = ({ title, description }) => {
  const { settings } = useAppContext();
  const titleToUse = `${title || staticText.defaultPageTitle} | Lasagna.app`;
  const descriptionToUse = description || staticText.defaultPageDescription;

  const { analytics, brandColor, fontFamily } = settings || {};
  const isGoogleFont = (fontFamily || {}).type === "google";
  const isCustomFont = (fontFamily || {}).type === "custom";

  const createFontFace = ({ label, weight, woff2 }) => `
    @font-face {
      font-family: '${label}';
      font-style: normal;
      font-weight: ${weight};
      src: url('${woff2}') format('woff2');
    }
  `;

  return (
    <Head>
      <title>{titleToUse}</title>
      <meta name="title" content={titleToUse} />
      <meta name="description" content={descriptionToUse} />
      <meta property="og:title" content={titleToUse} />
      <meta property="og:description" content={descriptionToUse} />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={titleToUse} />
      <meta property="twitter:description" content={descriptionToUse} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link
        rel="manifest"
        href="/manifest.json"
        crossOrigin="use-credentials"
      />
      <link
        rel="mask-icon"
        href="/favicons/safari-pinned-tab.svg"
        color="#f43545"
      />
      <link rel="shortcut icon" href="/favicons/favicon.ico" />
      <meta name="msapplication-TileColor" content="#f43545" />
      <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
      <meta name="theme-color" content="#f43545" />

      <style
        dangerouslySetInnerHTML={{
          __html: `
        :root {
          --brand-color: ${brandColor || "#0000ff"};
        }
      `,
        }}
      />

      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      {analytics && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${analytics}`}
        />
      )}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_MEASUREMENT_ID}', { anonymize_ip: true });
            gtag('config', '${analytics}', { anonymize_ip: true });
          `,
        }}
      />

      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
      {isGoogleFont && (
        <link
          href={`https://fonts.googleapis.com/css?family=${fontFamily.label.replace(
            / /g,
            "+"
          )}:400,600,700&display=swap`}
          rel="stylesheet"
        />
      )}

      {isCustomFont && (
        <style
          dangerouslySetInnerHTML={{
            __html: Object.values((fontFamily || {}).files || {})
              .map(({ weight, woff2 }) =>
                createFontFace({ weight, woff2, label: fontFamily.label })
              )
              .join("\n"),
          }}
        />
      )}
    </Head>
  );
};
