import { Loading } from 'components/Loading'
import { PageHead } from 'components/PageHead'
import { staticText } from 'content/staticText'

export const LoadingView = () => (
  <>
    <PageHead title={staticText.loadingTitle} />
    <Loading />
  </>
)
