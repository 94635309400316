import { CloseIcon } from 'components/Icons'
import { useEffect } from 'react'
import styles from './Overlay.module.css'

export const Overlay = ({ children, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  return (
    <div className={styles.overlay}>
      <div className={styles.background} onClick={onClose} />
      <div className={styles.container}>
        <button className={styles.closeButton} onClick={onClose} type="button">
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  )
}
