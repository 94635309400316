import { useEffect, useState } from 'react'
import styles from './Notification.module.css'

export const Notification = ({ message, onDone, duration = 2000 }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timeout = onDone ? setTimeout(onDone, duration) : null
    setIsVisible(true)
    return () => clearTimeout(timeout)
  })

  return (
    <div className={styles.notificationContainer}>
      <div
        className={styles.notification}
        style={isVisible ? { transform: `translateY(0)`, opacity: 1 } : { transform: `translateY(-100%)`, opacity: 0 }}
      >
        <div>{message}</div>
      </div>
    </div>
  )
}
