import { Button } from "components/Button";
import { Input } from "components/Input";
import { LoginIcon } from "components/Icons";
import { Overlay } from "components/Overlay";
import { RichText } from "components/RichText";
import { signIn } from "shared/firebase";
import { staticText } from "content/staticText";
import { useAppContext } from "shared/AppContext";
import { useState } from "react";
import styles from "./SignInOverlay.module.css";

export const SignInOverlay = ({ onClose }) => {
  const { firebaseInstances } = useAppContext();
  const onSignIn = ({ email, password }) =>
    signIn({ auth: firebaseInstances.auth, email, password });

  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    return onSignIn({ email, password })
      .then(onClose)
      .catch((error) => {
        setError(
          "Het e-mailadres of wachtwoord is incorrect. Controleer de gegevens en probeer het nog eens."
        );
      });
  };

  return (
    <Overlay onClose={onClose}>
      <header className={styles.group}>
        <RichText>
          <h2>{staticText.signInTitle}</h2>
          <p>{staticText.signInText}</p>
        </RichText>
      </header>
      <form onSubmit={onSubmit}>
        <div className={styles.group}>
          <Input
            label={staticText.email}
            value={email || ""}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles.group}>
          <Input
            label={staticText.password}
            value={password || ""}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Button icon={<LoginIcon />}>{staticText.signIn}</Button>
        {error && <div style={{ color: "red", marginTop: 20 }}>{error}</div>}
      </form>
    </Overlay>
  );
};
