import { onBlurCurrentTarget } from "shared/onBlurCurrentTarget";
import { ChevronIcon, SearchIcon } from "components/Icons";
import { staticText } from "content/staticText";
import { useEffect, useState } from "react";
import Link from "next/link";
import styles from "./SearchBox.module.css";

export const SearchBox = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const getResultsForQuery = async (query) => {
      const { results } = await fetch(`/api/search?q=${query}`).then((r) =>
        r.json()
      );
      setResults(results);
    };

    getResultsForQuery(query);
  }, [query]);

  return (
    <div
      className={styles.container}
      onBlur={(e) => onBlurCurrentTarget(e, () => setResults([]))}
    >
      <SearchInput onChange={setQuery} value={query} />
      {results && results.length > 0 && (
        <ResultsContainer>
          {results.splice(0, 3).map((result, index) => (
            <Result key={index} {...result} />
          ))}
        </ResultsContainer>
      )}
    </div>
  );
};

const SearchInput = ({ onChange, value }) => (
  <div className={styles.inputContainer}>
    <label className={styles.label} htmlFor="search">
      Zoeken
    </label>
    <input
      className={styles.input}
      id="search"
      onChange={(e) => onChange(e.target.value)}
      placeholder={staticText.search}
      type="search"
      value={value}
    />
    <SearchIcon />
  </div>
);

const ResultsContainer = ({ children }) => (
  <div className={styles.resultsContainer}>{children}</div>
);

const Result = ({ item, matches }) => {
  const { chapter, group, title, id } = item;
  const firstMatch = matches[0];
  const highlightString = firstMatch
    ? generateHighlightedText(firstMatch.value, firstMatch.indices)
    : null;

  return (
    <Link href="/[pageId]" as={`/${id}`}>
      <a className={styles.result}>
        <span className={styles.resultHeader}>
          <span className={styles.title}>{title}</span>
          <span className={styles.group}>
            {chapter && `${chapter.charAt(0).toUpperCase()}${chapter.slice(1)}`}
            {group?.name !== "FAKE_GROUP" ? ` / ${group.name}` : ""}
          </span>
        </span>

        {highlightString && (
          <span
            className={styles.preview}
            dangerouslySetInnerHTML={{ __html: highlightString }}
          />
        )}

        <ChevronIcon />
      </a>
    </Link>
  );
};

const generateHighlightedText = (inputText, regions) => {
  let content = "";
  let nextUnhighlightedRegionStartingIndex = 0;

  regions.forEach((region) => {
    const lastRegionNextIndex = region[1] + 1;

    content += [
      inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
      `<em>`,
      inputText.substring(region[0], lastRegionNextIndex),
      "</em>",
    ].join("");

    nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
  });

  content += inputText.substring(nextUnhighlightedRegionStartingIndex);

  return content;
};
