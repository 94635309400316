import { Button } from 'components/Button'
import { DeleteIcon, SaveIcon } from 'components/Icons'
import { Input } from 'components/Input'
import { Overlay } from 'components/Overlay'
import { RichText } from 'components/RichText'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import styles from './EditPageOverlay.module.css'

export const EditPageOverlay = ({ onClose, onTitleSave, onPageDelete, title, forGroup }) => {
  const [error, setError] = useState(null)
  const [localTitle, setLocalTitle] = useState(title)

  const onSubmit = (e) => {
    e.preventDefault()
    onTitleSave(localTitle).then(onClose).catch(setError)
  }

  const onDelete = (e) => {
    e.preventDefault()
    onPageDelete().then(onClose).catch(setError)
  }

  return (
    <Overlay onClose={onClose}>
      <div className={styles.group}>
        <RichText>
          <h2>{forGroup ? staticText.changeGroupNameTitle : staticText.changePageNameTitle}</h2>
          <p>{forGroup ? staticText.changeGroupNameText : staticText.changePageNameText}</p>
        </RichText>
        <form onSubmit={onSubmit}>
          <div className={styles.inputContainer}>
            <Input
              label={forGroup ? 'Groepnaam' : 'Paginanaam'}
              value={localTitle || ''}
              type="text"
              onChange={(e) => setLocalTitle(e.target.value)}
            />
          </div>
          <Button icon={<SaveIcon />}>{staticText.save}</Button>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </form>
      </div>
      {onPageDelete && (
        <div>
          <RichText>
            <h2>{staticText.deletePageTitle}</h2>
            <p>{staticText.deletePageText}</p>
          </RichText>
          <Button icon={<DeleteIcon />} onClick={onDelete}>
            {staticText.deletePageTitle}
          </Button>
        </div>
      )}
    </Overlay>
  )
}
