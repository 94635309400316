import {
  AddGroupIcon,
  AddIcon,
  ToggleIcon,
  CloseIcon,
  DragIcon,
  MenuIcon,
  ImageIcon,
  LockIcon,
  LoginIcon,
  LogoutIcon,
  MoreIcon,
  SettingsIcon,
} from "components/Icons";
import { arrayMove } from "shared/arrayMove";
import { captureException } from "shared/sentry";
import { EditPageOverlay } from "components/EditPageOverlay";
import { Loading } from "components/Loading";
import { SearchBox } from "components/SearchBox";
import { SignInOverlay } from "components/SignInOverlay";
import { signOut } from "shared/firebase";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { staticText } from "content/staticText";
import { useAppContext } from "shared/AppContext";
import {
  useFirestore,
  createPage,
  deletePage,
  updatePageIndex,
  updatePageContent,
  updateGroup,
} from "shared/firebase";
import { useRef, useEffect, useState } from "react";
import cx from "classnames";
import Link from "next/link";
import styles from "./Sidebar.module.css";

const FAKE_GROUP = "FAKE_GROUP";

const SortableContainer = sortableContainer(({ items, renderComponent }) => (
  <div>{items.map(renderComponent)}</div>
));
const SortableHandle = sortableHandle(({ children }) => <div>{children}</div>);
const SortableItem = sortableElement(({ children }) => <div>{children}</div>);

const chapters = [
  { id: "merk", label: "Merk" },
  { id: "design", label: "Design" },
  { id: "schrijfwijzer", label: "Schrijfwijzer" },
  { id: "richtlijnen", label: "Richtlijnen" },
  { id: "downloads", label: "Downloads" },
  { id: "contact", label: "Contact" },
];

export const Sidebar = ({ pageId }) => {
  const { firebaseInstances, settings, user } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [pageId]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={() => setIsOpen(false)} />
      )}
      <div className={styles.mobileContainer}>
        <button
          aria-label="Menu openen knop"
          className={styles.mobileMenuButton}
          onClick={() => setIsOpen(!isOpen)}
        >
          <MenuIcon />
        </button>
        <Link href="/" as="/">
          <a>
            <Logo logo={(settings || {}).siteLogo} />
          </a>
        </Link>
        <div />
      </div>
      <div className={cx(styles.container, { [styles.isOpen]: isOpen })}>
        <div className={styles.logoSearchContainer}>
          <div className={styles.logoContainer}>
            <button
              aria-label="Menu sluiten knop"
              className={styles.mobileMenuButton}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className={styles.mobileMenuButtonInner}>
                <CloseIcon />
                <span>Sluiten</span>
              </span>
            </button>

            <Link href="/" as="/">
              <a className={styles.sidebarLogo}>
                <Logo logo={(settings || {}).siteLogo} />
              </a>
            </Link>
          </div>
          <div className={styles.searchBoxContainer}>
            <SearchBox key={pageId} />
          </div>
        </div>
        <div className={styles.menuContainer}>
          <Menu
            pageId={pageId}
            firebaseInstances={firebaseInstances}
            user={user}
          />
        </div>
        <div className={styles.bottomMenuContainer}>
          <FooterMenu auth={firebaseInstances.auth} user={user} />
        </div>
      </div>
    </>
  );
};

const Logo = ({ logo }) =>
  logo ? (
    <img className={styles.logo} src={logo.url} alt={logo.alt || "Logo"} />
  ) : (
    <div />
  );

const FooterMenu = ({ auth, user }) => {
  const [showSignIn, setShowSignIn] = useState(false);
  const onSignOut = () => signOut({ auth });

  return (
    <div>
      {user ? (
        <>
          <FooterMenuButton href="/media" icon={<ImageIcon />}>
            {staticText.media}
          </FooterMenuButton>
          <FooterMenuButton href="/instellingen" icon={<SettingsIcon />}>
            {staticText.settings}
          </FooterMenuButton>
          <FooterMenuButton onClick={onSignOut} icon={<LogoutIcon />}>
            {staticText.signOut}
          </FooterMenuButton>
        </>
      ) : (
        <FooterMenuButton
          onClick={() => setShowSignIn(true)}
          icon={<LoginIcon />}
        >
          {staticText.signIn}
        </FooterMenuButton>
      )}

      {showSignIn && <SignInOverlay onClose={() => setShowSignIn(false)} />}
    </div>
  );
};

const FooterMenuButton = ({ children, href, onClick, icon }) => {
  const inner = (
    <span className={styles.footerMenuButtonInner}>
      {icon}
      <span>{children}</span>
    </span>
  );

  if (href) {
    return (
      <Link href={href}>
        <a className={styles.footerMenuButton}>{inner}</a>
      </Link>
    );
  }

  return (
    <button className={styles.footerMenuButton} onClick={onClick}>
      {inner}
    </button>
  );
};

const Menu = ({ pageId, firebaseInstances, user }) => {
  const { database } = firebaseInstances;
  const menuRef = useRef();

  const { data, loading } = useFirestore({
    database,
    path: `pagesIndex`,
    isCollection: true,
  });

  if (loading) {
    return <Loading />;
  }

  const onGroupSortEnd = ({ newIndex, oldIndex, items }) => {
    const newSorting = onSortEnd({ items, newIndex, oldIndex });
    newSorting.forEach(({ name, position }) =>
      updateGroup({ database, name, group: { name, position } })
    );
  };

  const onPageSortEnd = ({ newIndex, oldIndex, items }) => {
    const newSorting = onSortEnd({ items, newIndex, oldIndex });
    newSorting.forEach(({ id, position }) =>
      updatePageIndex({ database, id, data: { position } })
    );
  };

  const onAddPage = ({ chapter, title }) => {
    const group = { name: FAKE_GROUP, position: 0 };
    createPage({ chapter, database, group, title }).catch(captureException);
  };

  const menuData = formatMenuData({ chapters, pagesIndex: data });
  const chapterHasFakeGroup = (chapterId) => {
    const chapter = menuData.find(({ id }) => id === chapterId);
    const { content } = chapter;

    if (!content || !content.length) {
      return false;
    }

    const fakeGroupIndex = content.findIndex(({ name }) => name === FAKE_GROUP);
    return fakeGroupIndex > -1;
  };

  const activeChapter = data.filter(({ id }) => id === pageId)[0]?.chapter;

  return (
    <div className={styles.menu} ref={menuRef}>
      {menuData.map(({ name, content, id }, index) => (
        <MenuChapter
          chapter={id}
          database={database}
          defaultOpen={activeChapter === id}
          key={index}
          name={name}
          user={user}
        >
          {user && !chapterHasFakeGroup(id) && (
            <AddButton
              className={cx(styles.addPageButton, styles.isFakeGroup)}
              onClick={() =>
                onAddPage({ chapter: id, title: staticText.newPage })
              }
              icon={<AddIcon />}
            >
              Pagina toevoegen
            </AddButton>
          )}
          <SortableContainer
            helperContainer={menuRef.current}
            items={content}
            lockAxis="y"
            onSortEnd={({ newIndex, oldIndex }) =>
              onGroupSortEnd({ newIndex, oldIndex, items: content })
            }
            useDragHandle
            renderComponent={({ name, content, position }, index) => (
              <SortableItem key={index} index={index}>
                <MenuGroup
                  chapter={id}
                  database={database}
                  isFakeGroup={name === FAKE_GROUP}
                  name={name}
                  position={position}
                  user={user}
                >
                  <SortableContainer
                    helperContainer={menuRef.current}
                    items={content}
                    lockAxis="y"
                    onSortEnd={({ newIndex, oldIndex }) =>
                      onPageSortEnd({ newIndex, oldIndex, items: content })
                    }
                    useDragHandle
                    renderComponent={({ title, id, password }, index) => (
                      <SortableItem key={index} index={index}>
                        <MenuPage
                          database={database}
                          id={id}
                          isActive={id === pageId}
                          inFakeGroup={name === FAKE_GROUP}
                          isLocked={!!password}
                          title={title}
                          user={user}
                        />
                      </SortableItem>
                    )}
                  />
                </MenuGroup>
              </SortableItem>
            )}
          />
        </MenuChapter>
      ))}
    </div>
  );
};

const MenuChapter = ({
  chapter,
  children,
  defaultOpen = false,
  database,
  name,
  user,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(true);
    }
  }, [defaultOpen]);

  const onAddGroup = (name) => {
    const position = new Date().getTime();
    const group = { name, position };

    createPage({ chapter, database, group, title: staticText.newPage }).catch(
      captureException
    );
  };

  return (
    <div className={styles.menuChapter}>
      <h2
        className={cx(styles.menuChapterTitle, {
          [styles.menuChapterTitleOpen]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ToggleIcon />
        {name}
      </h2>
      {isOpen && (
        <>
          <div>{children}</div>
          {user && (
            <AddButton
              className={styles.menuChapterAddGroupButton}
              onClick={() => onAddGroup(`${staticText.newGroup}-${randomId()}`)}
              icon={<AddGroupIcon />}
            >
              Groep toevoegen
            </AddButton>
          )}
        </>
      )}
    </div>
  );
};

const MenuGroup = ({
  children,
  chapter,
  database,
  isFakeGroup,
  name,
  position,
  user,
}) => {
  const [isOpen, setIsOpen] = useState(isFakeGroup ? true : false);
  const [showMoreOverlay, setShowMoreOverlay] = useState(false);

  const onAddPage = (title) => {
    const group = { name, position };
    createPage({ chapter, database, group, title }).catch(captureException);
  };

  return (
    <div className={styles.menuGroup}>
      {!isFakeGroup && (
        <WithMenuButtons onMore={() => setShowMoreOverlay(true)} user={user}>
          <h3
            className={cx(styles.menuGroupTitle, {
              [styles.menuGroupTitleOpen]: isOpen,
            })}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ToggleIcon />
            {name}
          </h3>
        </WithMenuButtons>
      )}
      {isOpen && (
        <>
          <div>{children}</div>
          {user && (
            <AddButton
              className={cx(styles.addPageButton, {
                [styles.isFakeGroup]: isFakeGroup,
              })}
              onClick={() => onAddPage(staticText.newPage)}
              icon={<AddIcon />}
            >
              Pagina toevoegen
            </AddButton>
          )}
        </>
      )}
      {showMoreOverlay && (
        <EditPageOverlay
          forGroup
          onClose={() => setShowMoreOverlay(false)}
          onTitleSave={(newName) =>
            updateGroup({ database, name, group: { name: newName, position } })
          }
          title={name}
        />
      )}
    </div>
  );
};

const MenuPage = ({
  title,
  id,
  isActive,
  database,
  isLocked,
  user,
  inFakeGroup,
}) => {
  const [showMoreOverlay, setShowMoreOverlay] = useState(false);

  return (
    <>
      <WithMenuButtons onMore={() => setShowMoreOverlay(true)} user={user}>
        <Link href={"/[pageId]"} as={`/${id}`}>
          <a
            className={cx(styles.menuPage, {
              [styles.inFakeGroup]: inFakeGroup,
              [styles.menuPageActive]: isActive,
            })}
          >
            <span>{title}</span>
            {isLocked && <LockIcon />}
          </a>
        </Link>
      </WithMenuButtons>
      {showMoreOverlay && (
        <EditPageOverlay
          onClose={() => setShowMoreOverlay(false)}
          onPageDelete={() => deletePage({ database, id })}
          onTitleSave={(title) =>
            Promise.all([
              updatePageIndex({ database, id, data: { title } }),
              updatePageContent({ database, pageId: id, data: { title } }),
            ])
          }
          title={title}
        />
      )}
    </>
  );
};

const WithMenuButtons = ({ children, onMore, user }) => {
  if (!user) {
    return children;
  }

  return (
    <div className={styles.withMenuButtons}>
      <SortableHandle>
        <button className={styles.withMenuButtonsDragIcon}>
          <DragIcon />
        </button>
      </SortableHandle>
      <div>{children}</div>
      <button className={styles.withMenuButtonsMoreIcon} onClick={onMore}>
        <MoreIcon />
      </button>
    </div>
  );
};

const AddButton = ({ className, onClick, children, icon }) => (
  <button className={className} onClick={onClick}>
    <span className={styles.addButtonInner}>
      {icon}
      <span>{children}</span>
    </span>
  </button>
);

function formatMenuData({ chapters, pagesIndex }) {
  return chapters.map(({ id, label }) => ({
    name: label,
    id,
    content: formatGroupsAndPages(
      pagesIndex.filter(({ chapter }) => chapter === id)
    ),
  }));

  function formatGroupsAndPages(pages) {
    const groupsObject = {};

    pages.forEach((page) => {
      const { name, position } = page.group;
      const content = groupsObject[name]?.content || [];

      groupsObject[name] = {
        name,
        position,
        content: [...content, page].sort((a, b) => a.position - b.position),
      };
    });

    return Object.values(groupsObject).sort((a, b) => a.position - b.position);
  }
}

function onSortEnd({ newIndex, oldIndex, items }) {
  const updatePositions = (items) =>
    items.map((item, index) => ({ ...item, position: index * 100 }));
  const newSorting = updatePositions(arrayMove(items, oldIndex, newIndex));

  return newSorting;
}

function randomId() {
  return Math.random().toString(36).substring(2);
}
