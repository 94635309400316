import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { firebaseConfigs } from "./firebase-configs";
import { useState, useEffect } from "react";
import fb from "firebase/app";

export const getFirebaseInstances = (subdomain) => {
  const config = firebaseConfigs[subdomain] || null;

  if (!config) {
    return {};
  }

  const firebase = !fb.apps.length ? fb.initializeApp(config) : fb.app();

  return {
    auth: firebase.auth(),
    database: firebase.firestore(),
    storage: firebase.storage(),
  };
};

export const getFirebaseDatabaseOnServer = async (subdomain) => {
  const config = subdomain === "root" ? null : firebaseConfigs[subdomain];

  if (!config) {
    return null;
  }

  if (fb.apps.length) {
    await fb.apps[0].delete();
  }

  const firebase = fb.initializeApp(config);
  return firebase.firestore();
};

export const useFirestore = ({ database, path, isCollection }) => {
  const [data, setData] = useState(isCollection ? [] : {});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!database) {
      return;
    }

    const snapshotHandler = (snapshot) => {
      setError(null);
      setLoading(false);

      if (isCollection) {
        setData(snapshot.docs.map((doc) => doc.data()));
      }

      if (snapshot.exists) {
        setData(snapshot.data());
      }
    };

    const errorHandler = (err) => {
      captureException(err);
      setError(err);
      setLoading(false);
    };

    setData(isCollection ? [] : {});
    setError(null);
    setLoading(true);

    const unsubscribe = isCollection
      ? database.collection(path).onSnapshot(snapshotHandler, errorHandler)
      : database.doc(path).onSnapshot(snapshotHandler, errorHandler);

    return unsubscribe;
  }, [database, path, isCollection]);

  return { error, loading, data };
};

export const createPage = async ({
  chapter = null,
  title,
  group = null,
  database,
}) => {
  const position = new Date().getTime();
  const { id } = await database.collection(`/pagesIndex`).add({ position });

  const data = { chapter, group, id, position, title };
  const path = `/pagesIndex/${id}`;
  const contentPath = `/pagesContent/${id}`;

  return Promise.all([
    setFirestoreDoc({ database, path, data }),
    setFirestoreDoc({ database, path: contentPath, data: { title, id } }),
  ]);
};

export const updatePageContent = async ({ database, pageId, data }) => {
  if (!pageId) {
    return;
  }

  const path = `/pagesContent/${pageId}`;

  await setFirestoreDoc({ data, database, path });

  const doc = await database.doc(path).get();
  return doc.data();
};

export const updatePageIndex = async ({ database, id, data }) => {
  if (!id) {
    return;
  }

  const path = `/pagesIndex/${id}`;

  await setFirestoreDoc({ data, database, path });

  const doc = await database.doc(path).get();
  return doc.data();
};

export const deletePage = async ({ database, id }) => {
  const path = `/pagesIndex/${id}`;
  const contentPath = `/pagesContent/${id}`;
  const docRef = database.doc(path);
  const contentDocRef = database.doc(contentPath);

  return Promise.all([docRef.delete(), contentDocRef.delete()]);
};

export const updateGroup = async ({ database, name, group }) => {
  const pagesToEdit = await database
    .collection(`/pagesIndex/`)
    .where("group.name", "==", name)
    .get();
  const batch = database.batch();

  pagesToEdit.docs.forEach(({ id }) => {
    const path = `/pagesIndex/${id}`;
    const doc = database.doc(path);
    batch.set(doc, { group }, { merge: true });
  });

  return batch.commit();
};

export const updateSettings = ({ database, data }) => {
  const path = `/settings/settings`;
  return setFirestoreDoc({ database, path, data, merge: false });
};

export function useAuth(authInstance) {
  const [state, setState] = useState(() => {
    const user = authInstance ? authInstance.currentUser : null;
    return { userLoading: !user && !!authInstance, user };
  });

  useEffect(() => {
    const onChange = (user) => setState({ userLoading: false, user });
    const unsubscribe = authInstance
      ? authInstance.onAuthStateChanged(onChange)
      : (_) => _;

    return () => unsubscribe();
  }, [authInstance]);

  return state;
}

export const signIn = ({ auth, email, password }) =>
  auth.signInWithEmailAndPassword(email, password);
export const signOut = ({ auth }) => auth.signOut();

export const updateUserEmail = ({ auth, email }) => {
  const user = auth.currentUser;
  return user.updateEmail(email);
};

export const updateUserPassword = ({ auth, password }) => {
  const user = auth.currentUser;
  return user.updatePassword(password);
};

export const uploadFile = async ({
  storage,
  database,
  fileName,
  file,
  onProgress,
  onError,
  onComplete,
}) => {
  const doc = await database.doc(`/files/${fileName}`).get();

  if (doc.exists) {
    fileName = `${fileName}-${Math.random().toString(36).substring(2)}`;
  }

  const fileRef = storage.ref("files").child(fileName);
  const uploadTask = fileRef.put(file);
  uploadTask.on("state_changed", progressHandler, onError, successHandler);

  function progressHandler(snapshot) {
    const progress = Math.round(
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    );
    onProgress(progress);
  }

  async function successHandler() {
    const {
      contentType,
      type,
      fullPath,
      name,
    } = await uploadTask.snapshot.ref.getMetadata();
    const url = await uploadTask.snapshot.ref.getDownloadURL();
    const id = fileName;
    const data = { fullPath, name, url, id, alt: "", type, contentType };

    await setFirestoreDoc({ data, database, path: `/files/${id}` });
    onComplete(data);
  }
};

export const deleteFile = ({ storage, database, fullPath, id, onError }) => {
  const fileRef = storage.ref(fullPath);

  return fileRef
    .delete()
    .then(() => deleteFileFromDatabase({ database, id }))
    .catch(onError);

  function deleteFileFromDatabase({ database, id }) {
    const docRef = database.doc(`/files/${id}`);
    return docRef.delete();
  }
};

function setFirestoreDoc({ database, path, data, merge = true }) {
  const errorHandler = (error) => error && errorHandler(error);

  if (!database) {
    return errorHandler("setFirestoreDoc: database is missing");
  }

  if (!path) {
    return errorHandler("setFirestoreDoc: path is missing");
  }

  if (!data) {
    return errorHandler("setFirestoreDoc: data is missing");
  }

  data.lastUpdate = new Date().getTime();
  return database.doc(path).set(data, { merge });
}
