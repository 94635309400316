export const firebaseConfigs = {
  hu: {
    apiKey: "AIzaSyAKVZ58ajeFf3Kc1cyG1j4L-a6Svi7jbTE",
    appId: "1:16133710241:web:308a22bcb8c6f537932be0",
    authDomain: "hu-lasagna.firebaseapp.com",
    databaseURL: "https://hu-lasagna.firebaseio.com",
    messagingSenderId: "16133710241",
    projectId: "hu-lasagna",
    storageBucket: "hu-lasagna.appspot.com",
  },
  lasagna: {
    apiKey: "AIzaSyCua8ZoCF-Wu4jJYYvVXNx7yj5ReD5Tnbk",
    appId: "1:443114785603:web:0d1c91ee3ee4cf1c723df6",
    authDomain: "lasagna-lasagna.firebaseapp.com",
    databaseURL: "https://lasagna-lasagna.firebaseio.com",
    messagingSenderId: "443114785603",
    projectId: "lasagna-lasagna",
    storageBucket: "lasagna-lasagna.appspot.com",
  },
  eight: {
    apiKey: "AIzaSyCwlybFwsloTm8CGpsZ5TzyWlmE7MGLTrI",
    appId: "1:164200412712:web:baa5d0f0415ec022fce71d",
    authDomain: "eight-lasagna-6305a.firebaseapp.com",
    databaseURL: "https://eight-lasagna-6305a.firebaseio.com",
    messagingSenderId: "164200412712",
    projectId: "eight-lasagna-6305a",
    storageBucket: "eight-lasagna-6305a.appspot.com",
  },
  demo: {
    apiKey: "AIzaSyAvhsZV_79I0KVsYMk6Ph7WR3jnuP3qUxk",
    appId: "1:95607621648:web:5d4c293d23c64fdf6c2295",
    authDomain: "fir-lasagna.firebaseapp.com",
    databaseURL: "https://fir-lasagna.firebaseio.com",
    messagingSenderId: "95607621648",
    projectId: "fir-lasagna",
    storageBucket: "fir-lasagna.appspot.com",
  },
};
